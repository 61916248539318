import cardimg1 from './../../Images/cardimg1.jpg'
import cardimg2 from './../../Images/cardimg2.jpg'
import cardimg3 from './../../Images/cardimg3.jpg'
import cardimg4 from './../../Images/cardimg4.jpg'
import cardimg5 from './../../Images/cardimg5.jpg'
import cardimg6 from './../../Images/cardimg6.jpg'

const posts = [
    {
      id: 1,
      cover:cardimg1, 
      title: "What is Lorem Ipsum?",
      desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      id: 2,
      cover:cardimg2, 
      title: "What is Lorem Ipsum?",
      desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: 3,
      cover:cardimg3, 
      title: "What is Lorem Ipsum?",
      desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      id: 4,
      cover:cardimg4, 
      title: "What is Lorem Ipsum?",
      desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      id: 5,
      cover:cardimg5, 
      title: "What is Lorem Ipsum?",
      desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      id: 6,
      cover:cardimg6, 
      title: "What is Lorem Ipsum?",
      desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
]
  
export default posts
