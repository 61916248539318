import headerimg1 from './../../Images/headerimg1.jpg'

const BlogData = [
    {
        id: 1,
        titleOne: "TATJANA OLJAČA",
        titletwo: "A PSYCHOLOGIST WHO WRITES",
        paraTwo: "Because, why not",
        cover: headerimg1
    }
]

export default BlogData