import React from 'react'
import HomepageHeader from './HomepageHeader'
import SocialButtons from './../components/SocialButtons'

export default function Home() {
  return (
    <>
      <HomepageHeader />
      <SocialButtons />
    </>
  
  )
}
